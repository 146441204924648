.table {
  width: 75vw;
  border-collapse: collapse;
  border: 3px dashed var(--dark);
}

th,
td {
  padding: 12px 15px;
  text-align: left;
  border: 3px dashed var(--dark);
}

th {
  text-align: center;
}

.main {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-content: center;
  margin: auto;
  place-content: center;
  place-items: center;
}

.form {
  display: flex;
  flex-direction: column;

  border: 4px solid var(--dark);
  border-radius: 6px;
}

.form > input {
  margin: 10px;
  padding: 10px;
  border: 2px solid var(--dark);
  border-radius: 6px;
}
