* {
  transition: all 0.3s ease-in-out;
}

:root {
  --success: #4caf50;
  --danger: #f44336;
  --muted: #868686;
  --dark: #121212;
}

.main {
  background-color: white;
  color: black;

  font-size: 18px;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
