.orderForm {
  width: 300px;
  margin: 24px auto;
  padding: 20px;
  background-color: #f2f2f2;
  border: 1px solid var(--muted);
  border-radius: 4px;
}

h2 {
  text-align: center;
}

.formGroup {
  margin-bottom: 10px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="text"],
textarea {
  width: 100%;
  padding: 5px;
  border: 1px solid var(--muted);
  border-radius: 4px;
}

.submitButton {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: var(--success);
  color: #fff;
  border: none;
  border-radius: 4px;

  font-weight: 600;
  font-size: 16px;
}

.errorMessage {
  color: red;
  font-size: 0.8rem;
  margin-top: 5px;
}

.disabled {
  background-color: var(--muted);
}
