.main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
  align-content: flex-start;

  margin-top: 14px;
}

.nav {
  display: flex;
  flex-direction: row;

  min-height: 10vh;
  height: 100%;
  width: 100vw;

  justify-content: space-between;
  justify-items: stretch;
  align-content: center;
  align-items: center;
}

.nav > * {
  margin: 12px;
}

.container {
  display: flex;
  flex-direction: column;

  min-height: 90vh;
  height: 100%;
}

.danger {
  color: var(--danger);
}

.linkbtn {
  display: flex;
  flex-direction: row;

  justify-content: space-evenly;
  align-items: center;
  text-align: center;

  font-weight: 700;
  background-color: var(--success);
  color: white;
  text-decoration: none;
  border-radius: 4px;

  align-self: center;
}

.linkbtn > * {
  margin: 8px;
  padding: 8px;
}
