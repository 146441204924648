.container {
  width: 64px;

  display: flex;
  flex-direction: row;
  justify-content: center;
}

.cart {
  position: relative;
  width: 32px;
}

.count {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  position: absolute;
  background: red;
  color: white;
  top: -10px;
  right: -10px;

  border: 0px;
  border-radius: 9999px;
  cursor: pointer;

  width: 14px;
  height: 14px;
  padding: 6px;

  text-align: center;
  font-weight: 700;
}
