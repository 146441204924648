.input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60vw !important;
  margin: 0 auto;
  padding: 0 1rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
}
